export const routes = [
    {
      path: '/',
      name: 'Welcome',
      component: () => import('../components/Welcome.vue')
    },
    {
      path: '/quiz',
      name: 'Quiz',
      component: () => import('../components/Quiz.vue')
    },
    {
      path: '/impressum',
      name: 'Impressum',
      component: () => import('../components/Impressum.vue')
    },
    {
      path: '/katalog',
      name: 'Catalogue',
      component: () => import('../components/Catalogue.vue')
    },
    {
      path: '/links',
      name: 'OtherQuizzes',
      component: () => import('../components/OtherQuizzes.vue')
    },
    {
      path: '/datenschutz',
      name: 'Datenschutz',
      component: () => import('../components/Datenschutz.vue')
    },
    {
      path: '/baum/:treeName',
      name: 'Tree',
      component: () => import('../components/Tree.vue'),
      props: true,
      meta: {
        sitemap: {
          slugs: [
            'ahorn',
            'eiche',
            'fichte',
            'linde',
            'birke',
            'esche',
            'laerche',
            'eibe',
            'buche',
            'erle',
            'kastanie',
            'weide',
            'pappel'
          ]
        }
      }
    },
  ]