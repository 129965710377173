<template>
  <v-app>
    <v-app-bar 
    app
    absolute
    flat
    class="green lighten-5"> 
      <v-img :src="require('./assets/oak-309878_640.png')"
              max-height="50"
              max-width="50"
              class="mx-3"
              contain
              alt="Logo Baum Eiche"
              ></v-img>
      <v-toolbar-title style="cursor: pointer" @click="$router.push('/')">
              Baumquiz
      </v-toolbar-title>
      <router-link v-if="!mobile" to="/quiz" @click.native="$eventHub.$emit('startQuizEvent')">
        <v-btn class="ml-5" text> Quiz starten </v-btn>
      </router-link>
      <router-link v-if="!mobile" :to="{name: 'Catalogue' }">
        <v-btn class="ml-5" text> Baumkatalog </v-btn>
      </router-link>
      <router-link v-if="!mobile" :to="{name: 'OtherQuizzes' }">
        <v-btn class="ml-5" text> Weitere Baumquizze </v-btn>
      </router-link>
      <v-spacer></v-spacer>
      <v-menu offset-y
      v-if="mobile">
      <template v-slot:activator="{ on, attrs }">
        <v-app-bar-nav-icon
        v-bind="attrs"
        v-on="on"
          ></v-app-bar-nav-icon>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title>
            <router-link to="/quiz" @click.native="$eventHub.$emit('startQuizEvent')">
        <v-btn class="ml-5" text> Quiz starten </v-btn>
      </router-link>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <router-link :to="{name: 'Catalogue' }">
            <v-btn class="ml-5" text> Baumkatalog </v-btn>
            </router-link>
            </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <router-link :to="{name: 'OtherQuizzes' }">
            <v-btn class="ml-5" text> Weiter Baumquizze </v-btn>
            </router-link>
            </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer padless>
      <v-card flat tile width="100%" class="text-center">
        <v-card-text>
          <v-divider width="100%" class="mb-5"></v-divider>
          2021 - <b>Baumquiz</b> - Alle Fotos von
          <a href="http://www.pixabay.com" target="_blank">Pixabay.com</a>
          <p>
            <router-link to="/impressum">
              <v-btn text> Impressum </v-btn>
            </router-link>
            |
            <router-link to="/datenschutz">
              <v-btn text> Datenschutz </v-btn>
            </router-link>
          </p>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
  }),
  computed: {
    mobile: function () {
      return this.$vuetify.breakpoint.name == 'xs'
    }
  },
  metaInfo: {
        titleTemplate: '%s',
        meta: [
                { property: 'og:site_name', content: 'Baumquiz - Teste dein Wissen über heimische Bäume'},
                { property: 'og:type', content: 'website'},
                { name: 'robots', content: 'index,follow'}
            ]
      }
}
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
